/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import { Router, Route, Switch, match } from 'react-router-dom';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import LuigiClient from '@luigi-project/client';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';

import { renderRouteConfigV3, listenToEventBus } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import { getRandom, getURLParam, setDocumentLang } from './common/Utils';
import { MicroFrontend } from './features/common';
import {
  AppState,
  SetAppState,
  MFE,
  SetMFE,
  ConfigJson,
  UserInfo,
  UserRef,
  FetchFunctions,
  Settings,
} from './types';
import helper from './common/helpers';
import '@eureka/ui-managers/src/styles/layout.css';

type Props = Omit<FetchFunctions, 'usingMock'>;
type UserInfoRes = AxiosResponse<UserInfo>;

const { MessageToast } = eureka.controls;
const { initI18n, setLanguage, getUi5Language, getLocation } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig } = eurekaMgrs.ConfigManager;
const { setLanguage: configManagerSetLanguage } = eurekaMgrs.ConfigManager;
const { getDefaultThemeId, getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

let config: ConfigJson;
let lng = 'en-US';
let themeId = getDefaultThemeId();
const testingLng = getURLParam(window.location.search, 'sap-language');
const testingThemeId = getURLParam(window.location.search, 'sap-ui-theme');

const onFetchConfigSuccess = ({
  manifest,
  state,
  setState,
  setMicroFrontends,
}: {
  manifest: ConfigJson;
  state: AppState;
  setState: SetAppState;
  setMicroFrontends: SetMFE;
}) => {
  const shell = getLocation(manifest, manifest['shell-ui']);
  const microFrontends = [];
  manifest.components.forEach((component) => {
    const host = getLocation(manifest, component);
    microFrontends.push({
      name: component.config.app,
      host,
      routers: component.config.routers,
    });
  });
  config = manifest;
  setState({
    ...state,
    config,
  });
  setMicroFrontends(microFrontends);
  // add app config into config manager
  addConfig('appConfig', config);
  // init i18n
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en-US',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );
  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setState({
      ...state,
      initializing: false,
    });
  }
};

const onFetchAuthSuccess = ({ auth, user }: { auth: UserInfoRes; user: UserRef }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

export const onFetchAuthFailed = ({
  error,
  state,
  setState,
}: {
  error: any;
  state: AppState;
  setState: SetAppState;
}) => {
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=dxp';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState({ ...state, authUserError: error });
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState({
      ...state,
      initializing: false,
    });
  }
};

export const onFetchFinally = ({ state, setState }: { state: AppState; setState: SetAppState }) => {
  if (testingLng) {
    lng = testingLng;
  }
  if (testingThemeId) {
    themeId = testingThemeId;
  }
  // setThemeId(themeId);
  // setTheme(getThemeId());
  // setLanguage(lng);
  // configManagerSetLanguage(lng);
  // setUi5Language(getUi5Language(lng));
  // setDocumentLang(document, lng);

  // set initialization done
  setState((prevState) => ({
    ...prevState,
    initializing: false,
  }));
};

export const loader = () => <div>Loading...</div>;

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner className="page-load-spinner" />
    </div>
  );
};

export const renderError = (msg: string) => (
  <MessageStrip
    style={{ marginTop: '10px', marginRight: '10px' }}
    design={MessageStripDesign.Negative}
    hideIcon={false}
    hideCloseButton
  >
    {msg}
  </MessageStrip>
);

export const MicroFrontendWrapper = ({
  history,
  match,
  host,
  name,
  settings,
  user,
}: {
  history: History;
  match: match;
  host: string;
  name: string;
  settings: Settings;
  user: UserRef;
}) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
    />
  );
};

export const renderMicroFrontendRoutes = ({
  mfeRouters,
  history,
  settings,
  user,
}: {
  mfeRouters: MFE[];
  history: History;
  settings: Settings;
  user: UserRef;
}) => {
  const routes: React.ReactElement[] = [];
  mfeRouters.forEach((mfe) => {
    mfe.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              name={mfe.name}
              host={mfe.host}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

export const renderMfes = ({
  state,
  user,
  microFrontends,
}: {
  state: AppState;
  user: UserRef;
  microFrontends: MFE[];
}) => {
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfeRouters: microFrontends,
    history,
    settings: state.settings,
    user,
  });
  return (
    <Router history={history}>
      <Switch>
        {microFrontendRoutes}
        {containerRoutes}
      </Switch>
    </Router>
  );
};

const App: React.FC<Props> = ({ fetchConfig }) => {
  const [state, setState] = useState<AppState>({
    initializing: true,
    fetchConfigError: false,
    authUserError: false,
    config: {},
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState<MFE[] | []>([]);
  const user = useRef<UserInfo | Record<string, unknown>>({});

  useEffect(() => {
    // LuigiClient.luigiClientInit();
    const initListener = LuigiClient.addInitListener((initialContext) => {
      console.log('Luigi Client Initialized.');
      addConfig('isDxp', true);
      addConfig('dxpContext', initialContext);
      const { token, goBackContext } = initialContext;
      if (token) {
        addConfig('dxpToken', token);
      }
      if (goBackContext?.remotePromiseId) {
        const { remotePromiseId, action } = goBackContext;
        const { resolve, reject } = helper.unsubscribe(remotePromiseId);
        if (action && action === 'resolve') {
          resolve && resolve({ msg: 'Luigi Modal Closed', ...goBackContext });
        } else {
          reject && reject({ msg: 'Luigi Modal Closed', ...goBackContext });
        }
      }
      const lang = LuigiClient.uxManager().getCurrentLocale();
      const themeId = LuigiClient.uxManager().getCurrentTheme();
      if (lang) {
        // TODO:
        // setLanguage(lang);
        configManagerSetLanguage(lang);
        setDocumentLang(document, lang);
      }
      if (themeId) {
        setThemeId(themeId);
      }
      console.log('Initial Context', initialContext);
      console.log('Current Locale', lang);
      console.log('Current Theme', themeId);
    });
    const contextUpdateListener = LuigiClient.addContextUpdateListener((updatedContext) => {
      // console.log('Luigi Context Updated.', updatedContext);
      addConfig('dxpContext', updatedContext);
      const { token } = updatedContext;
      if (token) {
        addConfig('dxpToken', token);
      }
    });
    return () => {
      LuigiClient.removeContextUpdateListener(contextUpdateListener);
      LuigiClient.removeInitListener(initListener);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await fetchConfig();
        onFetchConfigSuccess({
          manifest: res?.data,
          state,
          setState,
          setMicroFrontends,
        });
        onFetchAuthSuccess({ auth: {}, user });
        onFetchFinally({ state, setState });
      } catch (error) {
        MessageToast.error(error?.error || error?.message);
      }
    };
    listenToEventBus({ history });
    addConfig('application', 'dxp');
    fetch();
  }, []);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends });
};

export default App;
