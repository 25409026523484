import React, { useEffect, useRef, useState, memo } from 'react';
import { useLocation } from 'react-router-dom';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';

import { MicroFrontendProps } from 'src/types';
import { DefaultLayout } from '../DefaultLayout';

type Props = MicroFrontendProps & {
  getManifest?: () => Promise<{ data: unknown }>;
  getAppPreference?: () => Promise<{ data: unknown }>;
};
type RenderMicroFrontend = MicroFrontendProps & {
  container: ContainerRef;
  fetchManifestError: boolean;
  userPreferencePromise: Promise<{
    data: unknown;
  }>;
};
type RenderApp = MicroFrontendProps & {
  container: ContainerRef;
};
type ContainerRef = React.MutableRefObject<HTMLDivElement | null>;

const { UserPreferenceManager, eventBus } = eurekaMgrs;
const { ProxyHelper } = eurekaMgrs.ProxyManager;
const { fetchManifest, fetchApplicationManifestAssets, renderApplication, unmountApplication } =
  ProxyHelper;
const { getCurrentLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;

export const hasLoggedin = () => {
  return !!window.hasLoggedin;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultGetAppPreference = (name: string) => {
  return Promise.resolve({
    data: {},
  });
};

export const renderApp = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
}: RenderApp) => {
  renderApplication(
    name,
    history,
    {
      history,
      match,
      host,
      user,
      eventBus,
      config,
      settings,
    },
    container,
    config,
  );
  /* istanbul ignore next */
  // eventBus.emit('i18n-update', null, getCurrentLanguage());
};

export const renderMicroFrontend = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
  fetchManifestError,
  userPreferencePromise,
}: RenderMicroFrontend) => {
  if (fetchManifestError) {
    return;
  }
  userPreferencePromise
    .then((res) => {
      UserPreferenceManager.setAppSetting(name, res.data);
    })
    .catch((err) => {
      /* istanbul ignore next */
      console.log('Load user preference error:', err);
    })
    .finally(() => {
      renderApp({ history, match, host, config, name, user, settings, container });
    });
};

export const MicroFrontendError = memo(() => {
  return (
    <MessageStrip
      style={{ margin: '10px' }}
      design={MessageStripDesign.Negative}
      hideIcon={false}
      hideCloseButton={false}
    >
      Failed to load asset manifest, please try again.
    </MessageStrip>
  );
});

const MicroFrontendInner = memo(
  ({ name, containerRef }: { name: string; containerRef: ContainerRef }) => {
    return (
      <div
        id="microfrontend-viewport"
        data-testid={`${name}-microfrontend-render-container`}
        style={{ height: '100%', background: 'var(--sapBackgroundColor)' }}
      >
        <div id={`${name.toLowerCase()}-container`} style={{ height: '100%' }}>
          <div
            id={`${name.toLowerCase()}-content`}
            className="microfrontent-content"
            ref={containerRef}
            style={{ height: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

/* istanbul ignore next */
const MicroFrontend: React.FC<Props> = ({
  history,
  match,
  host,
  name,
  config,
  settings,
  user,
  getManifest,
  getAppPreference = defaultGetAppPreference,
}) => {
  const location = useLocation();
  // const query = new URLSearchParams(location.search);
  const containerRef = useRef(null);
  const [fetchManifestError, setFetchManifestError] = useState(false);
  const userPreferencePromise = (getAppPreference || defaultGetAppPreference)(name);

  useEffect(() => {
    const containerCurrent = containerRef.current;
    if (name !== 'mfe-login' && !hasLoggedin()) {
      history.push('/login?application=dxp');
    } else if (containerCurrent) {
      fetchManifest({ host, name, getManifest }).then((manifest) => {
        // manifest host is equal to `http://localhost:2120`
        fetchApplicationManifestAssets(
          manifest,
          manifest?.files['main.js']?.startsWith('http') ? '' : host,
          name,
          config,
        ).then(
          () => {
            renderMicroFrontend({
              history,
              match,
              host,
              config,
              name,
              user,
              settings,
              container: containerCurrent,
              fetchManifestError,
              userPreferencePromise,
            });
          },
          (err) => {
            console.log('Load main asset error:', err);
            setFetchManifestError(true);
          },
        );
      });
    }
    return () => {
      if (fetchManifestError) {
        return;
      }
      unmountApplication(name);
    };
  }, []);

  if (name.toLowerCase() === 'mfe-login') {
    return (
      <div style={{ height: '100%' }}>
        <MicroFrontendInner name={name} containerRef={containerRef} />
      </div>
    );
  }

  return (
    <DefaultLayout match={match} history={history} config={config} user={user} settings={settings}>
      {fetchManifestError ? (
        <MicroFrontendError />
      ) : (
        <MicroFrontendInner name={name} containerRef={containerRef} />
      )}
    </DefaultLayout>
  );
};

export default MicroFrontend;
