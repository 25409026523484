let configMap;
let eventMap;

interface RemotePromise<T> extends Promise<T> {
  id: number;
  doResolve: () => void;
  doReject: () => void;
}

class Helper {
  getRandomId() {
    // window.msCrypto for IE 11
    return (window.crypto || window.msCrypto).getRandomValues(new Uint32Array(1))[0];
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const result = regex.exec(window.location.search);
    return (result && decodeURIComponent(result[1].replace(/\+/g, ' '))) || '';
  }

  qsParse(search: string) {
    const params = {};
    if (search) {
      const searchParams = new URLSearchParams(search);
      for (const [key, value] of searchParams.entries()) {
        Reflect.set(params, key, value);
      }
    }
    return params;
  }

  qsStringify(params: Record<string, string | number | boolean>) {
    let path = '';
    if (params) {
      const paramList = Object.entries(params);
      if (paramList.length > 0) {
        path += '?';
        for (const [key, value] of paramList) {
          path += `${key}=${value}&`;
        }
        path = path.slice(0, -1);
      }
    }
    return path;
  }

  createRemotePromise() {
    let res;
    let rej;
    const p = new Promise((resolve) => {
      res = () => {
        resolve(true);
      };
      rej = () => {
        resolve(false);
      };
    }) as RemotePromise<boolean>;

    if (!configMap) {
      configMap = {
        counter: 0,
        promises: [],
      };
    }
    p.id = configMap.counter++;
    configMap.promises[p.id] = p;

    p.doResolve = () => {
      delete configMap.promises[p.id];
      res();
    };
    p.doReject = () => {
      delete configMap.promises[p.id];
      rej();
    };

    return p;
  }

  getRemotePromise(id) {
    return configMap ? configMap.promises[id] : undefined;
  }

  subscribe(id, value) {
    if (!eventMap) {
      eventMap = {};
    }
    id = id || this.getRandomId();
    eventMap[id] = value;
    return id;
  }

  unsubscribe(id) {
    if (eventMap?.[id]) {
      const value = eventMap[id];
      Reflect.deleteProperty(eventMap, id);
      return value;
    }
  }
}

export default new Helper();
