import homeRoute from 'src/features/home/route';
import PageNotFound from 'src/features/common/PageNotFound';
import { RouteConfig } from 'src/types';

const childRoutes = [
  homeRoute,
  { path: '*', name: 'PageNotFound', component: PageNotFound },
].filter((r) => r.component || (r.childRoutes && r.childRoutes.length > 0));

const routes = [
  {
    path: '/',
    childRoutes,
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route: RouteConfig) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }
  const indexRoute = route.childRoutes.find((child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
