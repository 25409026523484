import React, { Suspense } from 'react';
import EurekaComponents from 'eureka';

import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import MessageToast from 'src/common/plugins/toast.plugin';
import { LayoutProps } from 'src/types';

const { Spinner } = EurekaComponents.components;

type Props = React.PropsWithChildren<LayoutProps>;

export const DefaultLayout: React.FC<Props> = (props) => {
  return (
    <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__app">
        <div className="fd-app">
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              {props.children}
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
    </div>
  );
};

export default DefaultLayout;
