import LuigiClient from '@luigi-project/client';
import helper from './helpers';

// const patch = (originalAction, eventName) => {
//   return function customAction(...args) {
//     const e = new Event(eventName);
//     originalAction.apply(this, args);
//     window.dispatchEvent(e);
//   };
// };

// const onHistoryChange = async (e) => {
//   const { pathname, search } = window.location;
//   const params = helper.qsParse(search);
//   LuigiClient.linkManager()
//     .withoutSync()
//     // .fromContext('project')
//     // .fromClosestContext()
//     // .fromParent()
//     .fromVirtualTreeRoot()
//     .preserveQueryParams(true)
//     .withParams(params)
//     .navigate(`${pathname}${search}`);
// };

const getURLSearchVal = (url: string) => {
  const values = ['/deployments', '/environment-management', '/access-management', '/erp-strategy', '/erp-signoff'];
  const value = values.find((v) => url.startsWith(v));
  return value;
};

const getNewRoute = (pathname = '', hash = '', url = '') => {
  let val = getURLSearchVal(url) || '';
  // TODO:
  val = val.startsWith('/') ? `#${val}` : `#/${val}`;
  hash = hash.replace(val, '').replace(/^\//, '');
  return `${pathname}${hash}`;
};

const onHashChange = async (e) => {
  if (!e.newURL && !e.oldURL) {
    return;
  }
  const { pathname, search, hash } = window.location;
  const [hashPath, hashSearch] = hash?.split('?');
  const currentRoute = await LuigiClient.linkManager().fromContext('project').getCurrentRoute();
  const newRoute = getNewRoute(pathname, hashPath, currentRoute);
  const customParams = helper.qsParse(hashSearch);
  // console.log('hashchange', e);
  console.log('hashchange', 'luigi navigation');
  console.log('路由切换了', 'from:', currentRoute, 'to:', newRoute);

  LuigiClient.linkManager()
    .withoutSync()
    // .fromContext('project')
    // .fromClosestContext()
    // .fromParent()
    // .preserveQueryParams(true)
    .fromVirtualTreeRoot()
    .withParams(customParams)
    .navigate(newRoute);
};

const onPopState = (e) => {
  if (!e.state) {
    return;
  }
  if (e.state === 'luiginavigation') {
    console.log('onpopstate', 'luigi navigation', e);
    window.history.back();
  }
};

const hackRouter = () => {
  // rewrite pushState replaceState, as these two don not trigger event
  // window.history.pushState = patch(window.history.pushState, 'custom_push');
  // window.history.replaceState = patch(window.history.replaceState, 'custom_replace');
  // window.addEventListener('custom_push', onHistoryChange);
  // window.addEventListener('custom_replace', onHistoryChange);
  // window.onpopstate = onHistoryChange;
  window.addEventListener('hashchange', onHashChange);
  window.addEventListener('popstate', onPopState);
};

export default hackRouter;
