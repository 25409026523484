import React from 'react';
import CacheRoute from 'react-router-cache-route';
// import { Route } from 'react-router-dom';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { History } from 'history';

import { registryLuigiEvents } from './common/luigi';
import { ConfigJson, Settings, UserRef } from './types';

const children: JSX.Element[] = []; // children component list
const { eventBus } = eurekaMgrs;
const { setLanguage } = eureka.I18nProvider;
const { setThemeId } = eurekaMgrs.AppearanceManager;

export function renderRouteConfigV3(
  routes,
  contextPath: string,
  config: ConfigJson,
  settings: Settings,
  user: UserRef,
) {
  // Resolve route config object in React Router v3.
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <CacheRoute
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <CacheRoute
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };
  routes.forEach((item) => renderRoute(item, contextPath));
  // Use Switch so that only the first matched route is rendered.
  return children;
}

export const listenToEventBus = ({ history }: { history: History }) => {
  /* istanbul ignore next */
  eventBus.on('i18n-update', (langCode) => {
    setLanguage(langCode);
  });

  /* istanbul ignore next */
  eventBus.on('appearance-update', (settings) => {
    console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
    const { themeChanged, themeId } = settings;
    if (themeChanged) {
      setThemeId(themeId);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  eventBus.on('navigate-page', (path, newState) => {
    if (path) {
      history.push(path, newState);
    }
  });

  registryLuigiEvents();
};
