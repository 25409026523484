import eurekaMgrs from '@eureka/ui-managers';
import LuigiClient from '@luigi-project/client';
import helper from 'src/common/helpers';

const { eventBus, addConfig } = eurekaMgrs;

type ModalSettings = {
  title?: string;
  size?: 'fullscreen' | 'l' | 'm' | 's';
  width?: string;
  height?: string;
  keepPrevious?: boolean;
};

type ConfirmationModalSettings = {
  type?: 'confirmation' | 'success' | 'warning' | 'error' | 'information';
  header: string;
  body: string;
  buttonConfirm: string;
  buttonDismiss: string;
};

type AlertSettings = {
  text: string;
  type: 'info' | 'success' | 'warning' | 'error';
  closeAfter?: number;
  links?: Record<string, { text: string; url?: string; dismissKey?: string }>;
};

type RouteConfig = {
  fromContext?: boolean | string;
  fromVirtualTreeRoot?: boolean;
  withoutSync?: boolean;
};

const defaultConfirmationModalSettings = {
  type: 'confirmation',
  header: '',
  body: '',
  buttonConfirm: 'OK',
  buttonDismiss: 'Cancel',
};

const defaultAlertSettings = {
  type: 'info',
  closeAfter: 3000,
};

export const LuigiEvents = [
  {
    name: 'navigate',
    callback: (path, config: RouteConfig) => {
      let lm = LuigiClient.linkManager();
      if (config.withoutSync) {
        lm = lm.withoutSync();
      }
      if (config.fromVirtualTreeRoot === true) {
        lm = LuigiClient.linkManager().fromVirtualTreeRoot();
      } else if (config.fromContext === true) {
        lm = LuigiClient.linkManager().fromClosestContext();
      } else if (config.fromContext) {
        lm = LuigiClient.linkManager().fromContext(config.fromContext);
      }
      if (path) {
        console.log('luigi navigate', path);
        lm.navigate(path);
      }
    },
  },
  {
    name: 'openAsModal',
    callback: (
      path: string,
      modalSettings: ModalSettings,
      nodeParams: LuigiClient.NodeParams = {},
      resolveCallback,
      rejectCallback,
    ) => {
      if (path) {
        const id = helper.subscribe(null, {
          name: 'openAsModalCallback',
          resolve: resolveCallback,
          reject: rejectCallback,
        });
        LuigiClient.linkManager()
          .fromVirtualTreeRoot()
          .withParams({ ...nodeParams, remotePromiseId: String(id) })
          // .withOptions({ preventHistoryEntry: true })
          .openAsModal(path, modalSettings);
      }
    },
  },
  {
    name: 'updateModalPathInternalNavigation',
    callback: (path: string, modalSettings?: ModalSettings, addHistoryEntry = false) => {
      LuigiClient.linkManager().updateModalPathInternalNavigation(
        path,
        modalSettings,
        addHistoryEntry,
      );
    },
  },
  // linkManager().navigateToIntent('user-settings', {project: 'pr', user: 'john'})
  // linkManager().navigate('/#?intent=user-settings?project=pr&user=john')
  {
    name: 'navigateToIntent',
    callback: (semanticSlug: string, params = {}) => {
      LuigiClient.linkManager().navigateToIntent(semanticSlug, params);
    },
  },
  {
    name: 'showConfirmationModal',
    callback: (settings: ConfirmationModalSettings, resolveCallback, rejectCallback) => {
      LuigiClient.uxManager()
        .showConfirmationModal({
          ...defaultConfirmationModalSettings,
          ...settings,
        })
        .then(() => {
          resolveCallback && resolveCallback();
        })
        .catch(() => {
          rejectCallback && rejectCallback();
        });
    },
  },
  {
    name: 'closeCurrentModal',
    callback: () => {
      LuigiClient.uxManager().closeCurrentModal();
    },
  },
  {
    name: 'showAlert',
    callback: (settings: AlertSettings, resolveCallback, rejectCallback) => {
      LuigiClient.uxManager()
        .showAlert({ ...defaultAlertSettings, ...settings })
        .then(() => {
          resolveCallback && resolveCallback();
        });
    },
  },
  {
    name: 'showLoadingIndicator',
    callback: () => {
      LuigiClient.uxManager().showLoadingIndicator();
    },
  },
  {
    name: 'hideLoadingIndicator',
    callback: () => {
      LuigiClient.uxManager().hideLoadingIndicator();
    },
  },
  {
    name: 'addBackdrop',
    callback: () => {
      LuigiClient.uxManager().addBackdrop();
    },
  },
  {
    name: 'removeBackdrop',
    callback: () => {
      LuigiClient.uxManager().removeBackdrop();
    },
  },
  {
    name: 'getContext',
    callback: (resolveCallback, rejectCallback) => {
      const context = LuigiClient.getContext();
      resolveCallback && resolveCallback(context);
    },
  },
  {
    name: 'getToken',
    callback: (resolveCallback, rejectCallback) => {
      const accessToken = LuigiClient.getToken();
      resolveCallback && resolveCallback(accessToken);
      addConfig('dxpToken', accessToken);
    },
  },
  {
    name: 'getNodeParams',
    callback: (shouldDesanitise, resolveCallback, rejectCallback) => {
      const params = LuigiClient.getNodeParams(shouldDesanitise);
      resolveCallback && resolveCallback(params);
    },
  },
  {
    name: 'getPathParams',
    callback: (resolveCallback, rejectCallback) => {
      const params = LuigiClient.getPathParams();
      resolveCallback && resolveCallback(params);
    },
  },
  {
    name: 'getSearchParams',
    callback: (resolveCallback, rejectCallback) => {
      const params = LuigiClient.getCoreSearchParams();
      resolveCallback && resolveCallback(params);
    },
  },
  {
    name: 'addNodeParams',
    callback: (params, keepBrowserHistory) => {
      LuigiClient.addNodeParams(params, keepBrowserHistory);
    },
  },
  {
    name: 'addSearchParams',
    callback: (params, keepBrowserHistory) => {
      LuigiClient.addCoreSearchParams(params, keepBrowserHistory);
    },
  },
  {
    name: 'goBack',
    callback: (goBackValue) => {
      LuigiClient.linkManager().goBack(goBackValue);
    },
  },
  {
    name: 'getCurrentRoute',
    callback: async (config: RouteConfig, resolveCallback, rejectCallback) => {
      let lm = LuigiClient.linkManager();
      if (config.fromVirtualTreeRoot) {
        lm = lm.fromVirtualTreeRoot();
      } else if (config.fromContext === true) {
        lm = lm.fromClosestContext();
      } else if (config.fromContext) {
        lm = lm.fromContext(config.fromContext);
      }
      try {
        const res = await lm.getCurrentRoute();
        resolveCallback && resolveCallback(res);
      } catch (error) {
        rejectCallback && rejectCallback(error);
      }
    },
  },
];

export const registryLuigiEvents = () => {
  LuigiEvents.forEach(({ name, callback }) => {
    eventBus.on(name, callback);
  });
};
