import eurekaMgrs from '@eureka/ui-managers';

const { getThemeList } = eurekaMgrs.AppearanceManager;

export function getRandom(digit = 5) {
  return `${Math.random()}`.slice(2, digit + 2);
}

export const loadCSS = (
  dom: Document,
  assetName: string,
  host: string,
  name: string,
  filePath: string,
  hash: string,
) => {
  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url: string, name: string) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

export const setDocumentLang = (doc: Document, langCode: string) => {
  doc && (doc.documentElement.lang = langCode);
};

// css from https://wiki.wdf.sap.corp/wiki/pages/viewpage.action?pageId=2697898341
export const THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #758ca4',
      boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: 'rgb(29, 45, 63)',
      border: '1px solid #1D2D3E',
      boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #000',
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: 'rgb(0, 0, 0)',
      border: '1px solid #FFF',
    },
    order: '40',
  },
];

export const getThemeById = (themeId: string) => {
  return (getThemeList() || []).find((ele) => ele.themeId === themeId);
};
