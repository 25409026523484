// import Index from '.';
import WelcomePage from './WelcomePage';
import ErrorPage from './ErrorPage';

export default {
  path: '/',
  name: 'HomePage',
  // component: Index,
  childRoutes: [
    { path: 'welcome-page', name: 'WelcomePage', component: WelcomePage, isIndex: true },
    { path: 'error', name: 'ErrorPage', component: ErrorPage },
  ],
};
