/* eslint-disable operator-linebreak */
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  MessageStrip,
  MessageStripDesign,
  Toast,
  ToastDomRef,
  ToastPlacement,
} from '@ui5/webcomponents-react';

const { eventBus } = eurekaMgrs;
const { useTranslation } = eureka.I18nProvider;

const MessageStripTypeEnum = {
  success: MessageStripDesign.Positive,
  warn: MessageStripDesign.Warning,
  warning: MessageStripDesign.Warning,
  info: MessageStripDesign.Information,
  error: MessageStripDesign.Negative,
  default: MessageStripDesign.Information,
};

const checkIsToastPlacement = (str: string): str is ToastPlacement => str in ToastPlacement;

const ToastPlugin = () => {
  const { t } = useTranslation();
  const toastRef = useRef<ToastDomRef>(null);
  const [toastProps, setToastProps] = useState({
    toastType: 'default',
    toastDuration: 3000,
    toastContent: '',
    toastPlacement: ToastPlacement.BottomCenter,
    toastIcon: null,
    iconContainerStyles: {},
    iconStyles: {},
    hideCloseButton: false,
    messageStripType: MessageStripDesign.Negative,
  });

  let className = ' eureka-message-toast-layout ';
  className += `eureka-message-toast-type-${toastProps.toastType}`;

  const eventHandler = (message: {
    type: string;
    content: string;
    duration: number;
    placement: string;
    hideCloseButton: boolean;
  }) => {
    if (message.content) {
      setToastProps({
        ...toastProps,
        toastType: message.type,
        toastContent: message.content,
        toastDuration: message.duration,
        toastPlacement:
          (checkIsToastPlacement(message.placement) && message.placement) ||
          ToastPlacement.BottomCenter,
        messageStripType: MessageStripTypeEnum[message.type],
        hideCloseButton: message.hideCloseButton,
      });
    }
  };

  const onToastClose = useCallback(() => {
    toastRef?.current?.removeAttribute('open');
  }, [toastRef]);

  useEffect(() => {
    eventBus.on('on-message-toast', eventHandler);
    return () => {
      eventBus.detach('on-message-toast', eventHandler);
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `.ui5-toast-root {
        padding: 0 !important;
        box-shadow: none !important;
        max-width: none !important;
      }`;
    if (toastRef.current && toastProps.toastContent && toastProps.toastContent !== '') {
      setTimeout(() => {
        toastRef.current?.shadowRoot?.prepend(style);
        toastRef.current?.show();
      });
    }
    return () => {
      style.remove();
    };
  }, [toastProps]);

  return (
    <Toast ref={toastRef} duration={toastProps.toastDuration} placement={toastProps.toastPlacement}>
      <div className={className} style={{ display: 'flex' }}>
        <MessageStrip
          design={toastProps.messageStripType}
          hideCloseButton={toastProps.hideCloseButton}
          hideIcon={false}
          onClose={onToastClose}
        >
          {t(toastProps.toastContent)}
        </MessageStrip>
      </div>
    </Toast>
  );
};

export default ToastPlugin;
