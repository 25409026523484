import React from 'react';
import ReactDOM from 'react-dom';
import eurekaMgrs from '@eureka/ui-managers';

import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import hackRouter from './common/hackRouter';
import { THEME_LIST } from './common/Utils';
import { FetchFunctions } from './types';
import './styles/index.less';

window.__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';

const { setThemeId, getDefaultThemeId, setDefaultThemeId, setThemeList } =
  eurekaMgrs.AppearanceManager;

const DEFAULT_THEME = 'sap_horizon';
const themeId = new URLSearchParams(window.location.search).get('sap-ui-theme');

// enable fiori next horizon theme
setDefaultThemeId(DEFAULT_THEME);
setThemeList(THEME_LIST);
setThemeId(themeId || getDefaultThemeId());

hackRouter();
const fetchFunctions = axiosWrapper() as FetchFunctions;

ReactDOM.render(<App {...fetchFunctions} />, document.getElementById('dxp-app'));

console.log('Shell UI Render');
